import React, { useState, useEffect, useCallback, useRef } from 'react';
import retry from 'stillnovel/utils/retry';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Button from 'stillnovel/components/UI/Button';
import CloseButton from 'stillnovel/components/UI/CloseButton';
import Loader from 'stillnovel/components/UI/Loader';
import Portal from 'stillnovel/components/UI/Portal';
import Resolve from 'stillnovel/components/UI/Resolve';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import Text from 'stillnovel/components/UI/Text';
import getConfig from 'core/config';
import { usePrevious } from 'react-use';
import productsConfig from 'stillnovel/components/App/Project/config';

import ErrorBoundary from './ErrorBoundary';
import ProductPreview from './ProductPreview';
import styles from './ArtPrintPreview.scss';

const SimpleFramedLoader = () =>
    import(/* webpackChunkName: "Simple" */ '@stillnovel/artistry/lib/Simple');

const previewComponents = {
    'birth-stat-modern': {
        component: () =>
            import(
                /* webpackChunkName: "BirthStatModern" */ '@stillnovel/artistry/lib/BirthStatModern'
            ),
    },

    'back-to-school-snapshot-style 02': {
        component: () =>
            import(
                /* webpackChunkName: "BackToSchoolSnapshotStyle02" */ '@stillnovel/artistry/lib/BackToSchoolSnapshotStyle02'
            ),
    },

    'birth-stat-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "BirthStatVintage" */ '@stillnovel/artistry/lib/BirthStatVintage'
            ),
    },

    'birth-stat-snapshot-style 02': {
        component: () =>
            import(
                /* webpackChunkName: "BirthStatSnapshotStyle02" */ '@stillnovel/artistry/lib/BirthStatSnapshotStyle02'
            ),
    },

    'wedding-modern': {
        component: () =>
            import(
                /* webpackChunkName: "WeddingModern" */ '@stillnovel/artistry/lib/WeddingModern'
            ),
    },
    'wedding-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "WeddingVintage" */ '@stillnovel/artistry/lib/WeddingVintage'
            ),
    },

    'travel-modern': {
        component: () =>
            import(
                /* webpackChunkName: "TravelModern" */ '@stillnovel/artistry/lib/TravelModern'
            ),
    },
    'travel-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "TravelVintage" */ '@stillnovel/artistry/lib/TravelVintage'
            ),
    },

    'travel-snapshot-style 02': {
        component: () =>
            import(
                /* webpackChunkName: "TravelSnapshotStyle02" */ '@stillnovel/artistry/lib/TravelSnapshotStyle02'
            ),
    },

    'all-about-you-modern': {
        component: () =>
            import(
                /* webpackChunkName: "AllAboutYouModern" */ '@stillnovel/artistry/lib/AllAboutYouModern'
            ),
    },
    'all-about-you-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "AllAboutYouVintage" */ '@stillnovel/artistry/lib/AllAboutYouVintage'
            ),
    },
    'memorial-modern': {
        component: () =>
            import(
                /* webpackChunkName: "AllAboutYouModern" */ '@stillnovel/artistry/lib/AllAboutYouModern'
            ),
    },
    'memorial-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "AllAboutYouVintage" */ '@stillnovel/artistry/lib/AllAboutYouVintage'
            ),
    },
    'parent-modern': {
        component: () =>
            import(
                /* webpackChunkName: "ParentModern" */ '@stillnovel/artistry/lib/ParentModern'
            ),
    },
    'parent-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "ParentVintage" */ '@stillnovel/artistry/lib/ParentVintage'
            ),
    },
    'parent-dad-modern': {
        component: () =>
            import(
                /* webpackChunkName: "ParentModern" */ '@stillnovel/artistry/lib/ParentModern'
            ),
    },
    'parent-dad-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "ParentVintage" */ '@stillnovel/artistry/lib/ParentVintage'
            ),
    },
    'parent-mom-modern': {
        component: () =>
            import(
                /* webpackChunkName: "ParentModern" */ '@stillnovel/artistry/lib/ParentModern'
            ),
    },
    'parent-mom-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "ParentVintage" */ '@stillnovel/artistry/lib/ParentVintage'
            ),
    },
    'pet-modern': {
        component: () =>
            import(
                /* webpackChunkName: "PetsModern" */ '@stillnovel/artistry/lib/PetsModern'
            ),
    },
    'pet-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "PetsVintage" */ '@stillnovel/artistry/lib/PetsVintage'
            ),
    },

    'couples-modern': {
        component: () =>
            import(
                /* webpackChunkName: "EngagementModern" */ '@stillnovel/artistry/lib/CouplesModern'
            ),
    },
    'couples-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "CouplesVintage" */ '@stillnovel/artistry/lib/CouplesVintage'
            ),
    },

    'engagement-modern': {
        component: () =>
            import(
                /* webpackChunkName: "EngagementModern" */ '@stillnovel/artistry/lib/EngagementModern'
            ),
    },
    'engagement-vintage': {
        component: () =>
            import(
                /* webpackChunkName: "EngagementVintage" */ '@stillnovel/artistry/lib/EngagementVintage'
            ),
    },

    'simple-framed-print-10x10': {
        component: SimpleFramedLoader,
        defaultProps: {
            printWidth: 10,
            printHeight: 10,
        },
    },
    'simple-framed-print-11x15': {
        component: SimpleFramedLoader,
        defaultProps: {
            printWidth: 11,
            printHeight: 15,
        },
    },
    'simple-framed-print-15x11': {
        component: SimpleFramedLoader,
        defaultProps: {
            printWidth: 15,
            printHeight: 11,
        },
    },
    'simple-framed-print-16x20': {
        component: SimpleFramedLoader,
        defaultProps: {
            printWidth: 16,
            printHeight: 20,
        },
    },
    'simple-framed-print-20x16': {
        component: SimpleFramedLoader,
        defaultProps: {
            printWidth: 20,
            printHeight: 16,
        },
    },
    'simple-framed-print-20x24': {
        component: SimpleFramedLoader,
        defaultProps: {
            printWidth: 20,
            printHeight: 24,
        },
    },
    'simple-framed-print-24x20': {
        component: SimpleFramedLoader,
        defaultProps: {
            printWidth: 24,
            printHeight: 20,
        },
    },
};

function LoadableArtPrintPreview(props) {
    const [portal, setPortal] = useState('closed');
    const prevPortal = usePrevious(portal);
    const portalRef = useRef(null);

    useEffect(() => {
        const ref = portalRef?.current;
        if (!ref) return;
        if (prevPortal !== portal && portal === 'open') {
            disableBodyScroll(ref);
        } else if (portal === 'closed') {
            enableBodyScroll(ref);
        }
        return () => {
            enableBodyScroll(ref);
        };
    }, [portal, prevPortal]);

    const handlePreviewClick = useCallback(() => {
        setPortal('open');
    }, [setPortal]);

    const handleCloseClick = useCallback(() => {
        setPortal('closed');
    }, [setPortal]);

    let productConfig = productsConfig[props?.builderHandle];

    if (productConfig?.getNewConfig) {
        // Makes projects with old build handles backward compatible
        productConfig = productConfig.getNewConfig(props);
    }

    const handle = productConfig?.previewLoaderHandle?.(props);

    useEffect(() => {
        if (!productConfig?.printWidth) {
            console.warn('No printWidth found for', handle);
        }
    }, [handle, productConfig?.printWidth]);

    if (!handle) {
        return <Text theme="body1-alt">Missing Project Handle</Text>;
    }

    // Find SKU
    const projectPreviewComponentMatch = previewComponents[handle];

    // No component found / Unsupported SKU
    if (!projectPreviewComponentMatch) {
        return <Text theme="body1-alt">No Preview for handle: {handle}</Text>;
    }

    const previewComponentImport = projectPreviewComponentMatch.component;
    const previewComponentProps =
        projectPreviewComponentMatch.defaultProps || {};

    const renderPDFDebugButton = () => (
        <Button
            href={`${getConfig('apiEndpoint').replace(
                '/api/',
                '/'
            )}projects/generate2/${props.id}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            theme="button"
            title="View PDF"
            className={styles['pdf-debug-link']}
        >
            <SvgIcon iconType="pdf" className={styles['button-icon']} />
        </Button>
    );

    const aspectRatio =
        productConfig?.printWidth / productConfig?.printHeight || 'auto';

    return (
        <ErrorBoundary>
            <Resolve promise={() => retry(previewComponentImport)}>
                {PreviewComponent => {
                    return (
                        <>
                            <div
                                className={styles.root}
                                style={{
                                    aspectRatio,
                                }}
                            >
                                <ProductPreview
                                    key={handle}
                                    frameImage={props?.frameImage}
                                    frameInlineStyles={props?.frameInlineStyles}
                                    sku={props.sku}
                                    active={props.inSitu}
                                >
                                    {PreviewComponent && (
                                        <PreviewComponent.default
                                            {...props}
                                            {...previewComponentProps}
                                        />
                                    )}
                                </ProductPreview>

                                {props.isLoading && (
                                    <div className={styles.loader}>
                                        <Loader />
                                    </div>
                                )}
                                <Button
                                    className={styles['lightbox-button']}
                                    onClick={handlePreviewClick}
                                />
                                {process.env.ENV !== 'production' &&
                                    renderPDFDebugButton()}
                            </div>

                            {portal === 'open' && (
                                <Portal>
                                    <div
                                        ref={portalRef}
                                        className={styles.portal}
                                        onClick={handleCloseClick}
                                    >
                                        <div className={styles['portal-inner']}>
                                            <div
                                                className={
                                                    styles['portal-content']
                                                }
                                            >
                                                <ProductPreview
                                                    key={handle}
                                                    sku={props.sku}
                                                    frameImage={
                                                        props?.frameImage
                                                    }
                                                    frameInlineStyles={
                                                        props?.frameInlineStyles
                                                    }
                                                    active={props.inSitu}
                                                >
                                                    <PreviewComponent.default
                                                        {...props}
                                                        {...previewComponentProps}
                                                        thumbnail={false}
                                                    />
                                                </ProductPreview>
                                                <CloseButton
                                                    className={
                                                        styles[
                                                            'portal-close-button'
                                                        ]
                                                    }
                                                    onClick={handleCloseClick}
                                                >
                                                    Close
                                                </CloseButton>
                                            </div>
                                        </div>
                                    </div>
                                </Portal>
                            )}
                        </>
                    );
                }}
            </Resolve>
        </ErrorBoundary>
    );
}

LoadableArtPrintPreview.propTypes = {
    builderHandle: PropTypes.any,
    id: PropTypes.any,
    inSitu: PropTypes.any,
    isLoading: PropTypes.bool,
    frameImage: PropTypes.any,
    frameInlineStyles: PropTypes.any,
    sku: PropTypes.any,
};

LoadableArtPrintPreview.defaultProps = {
    isLoading: false,
};

export default LoadableArtPrintPreview;
